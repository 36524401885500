import React,{useState} from 'react';


function Checkout () {

    const [Fname, setFname] = useState('');
    const [Lname, setLname] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [country, setCountry] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [state, setState] = useState('');
    const asyncPostCall = async () => {
        try {
            const response = await fetch('https://of.designer-dev.com/wp-json/wc/v3/orders?consumer_key=ck_555edaa14f9f2e2dbc69c27e4b549bce31cd3d93&consumer_secret=cs_6f2102593884b839e21e0234d2a70b9810206bce', {
             method: 'POST',
             headers: {
               'Content-Type': 'application/json'
               },
               body: JSON.stringify({
         // your expected POST request payload goes here
         payment_method: "cod",
         payment_method_title: "Cash On Delivery",
         set_paid: false,
         billing: {
           first_name: Fname,
           last_name: Lname,
           address_1: address,
           address_2: "",
           city: city,
           state: state,
           postcode: "94103",
           country: country,
           email: email,
           phone: phone
         },
         shipping: {
           first_name: Fname,
           last_name: Lname,
           address_1: address,
           address_2: "",
           city: city,
           state: state,
           postcode: "94103",
           country: country
         },
         line_items: [
           {
             product_id: "",
             sku:"top-shell-1",
             quantity: 2
           }
         ],
         shipping_lines: [
           {
             method_id: "flat_rate",
             method_title: "Flat Rate",
             total: "12.00"
           }
         ]
                })
             });
             const data = await response.json();
          // enter you logic when the fetch is successful
             console.log(data);
           } catch(error) {
         // enter your logic for when there is an error (ex. error toast)

              console.log(error)
             } 
        }


    const handleSubmit = (e) => {
        asyncPostCall()
        e.preventDefault();
    }
    
    
    
    return(
        <div>
            <form onSubmit={handleSubmit}>
                <input type="text" name="Frist Name" placeholder="FirstName" value={Fname} onChange={(e) => setFname(e.target.value)}/>
                <input type="text" name="LastName" placeholder="LastName" value={Lname} onChange={(e) => setLname(e.target.value)}/>
                <input type="text" name="Address" placeholder="Address" value={address} onChange={(e) => setAddress(e.target.value)}/>
                <input type="text" name="City" placeholder="City" value={city} onChange={(e) => setCity(e.target.value)}/>
                <input type="email" name="Email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)}/>
                <input type="number" name="Phone" placeholder="Phone" value={phone} onChange={(e) => setPhone(e.target.value)}/>
                <input type="text" name="State" placeholder="State" value={state} onChange={(e) => setState(e.target.value)}/>
                <input type="text" name="Country" placeholder="Country" value={country} onChange={(e) => setCountry(e.target.value)}/>
            <button>Submit</button>
            </form>
            
        </div>
    )
}

export default Checkout;